function isDocumentVisible () {
  if (typeof document.visibilityState !== 'undefined') {
    return document.visibilityState !== 'hidden'
  }
  // always assume it's visible
  return true
}

/**
 * @type {HTMLImageElement}
 */
const eyes = document.querySelector('[data-js="blinking-eyes"]')

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * @param {Map<'open'|'closed'|'blink', string>} images
 */
async function runLoop (images) {
  let timeElapsed = 0

  eyes.src = images.get('open')
  eyes.style.display = 'block'
  window.requestAnimationFrame(() => {
    window.requestAnimationFrame(() => {
      eyes.style.opacity = 1
    })
  })

  await delay(400)

  return new Promise((resolve) => {
    const loop = setInterval(async () => {
      switch (timeElapsed) {
        case 200:
          eyes.src = images.get('close')
          break
        case 600:
          eyes.src = images.get('open')
          break
        case 900:
          eyes.src = images.get('blink')
          break
        case 1050:
          eyes.src = images.get('open')
          break
        case 1875:
          eyes.src = images.get('close')
          break
        case 2075:
          eyes.src = images.get('open')
          break
        case 2325:
          eyes.src = images.get('close')
          break
        case 2550:
          eyes.src = images.get('open')
          break
      }

      if (timeElapsed >= 3000) {
        clearInterval(loop)
        eyes.style.opacity = 0
        await delay(400)
        eyes.style.display = 'none'
        resolve()
      }

      timeElapsed = timeElapsed + 25
    }, 25)
  })
}

export async function initializeEyes ({
  immediate = false,
  baseInterval = 30 * 1000
} = {}) {
  if (eyes) {
    const imageUrls = [
      { name: 'open', url: require('./img/NV_WEB_EYE_1.png') },
      { name: 'close', url: require('./img/NV_WEB_EYE_2.png') },
      { name: 'blink', url: require('./img/NV_WEB_EYE_3.png') }
    ]
    const TEN_SECONDS_IN_MS = 10 * 1000
    const images = new Map()

    /**
     * @param {number} timeout
     */
    const scheduleAnimation = (timeout) => {
      setTimeout(async () => {
        // preload and cache images
        if (images.size < 3) {
          await Promise.all(
            imageUrls.map(({ name, url }) => {
              return window
                .fetch(url)
                .then((response) => response.blob())
                .then((blob) => images.set(name, URL.createObjectURL(blob)))
            })
          )
        }

        if (isDocumentVisible()) {
          await runLoop(images)
        }

        // schedule next animation
        scheduleAnimation(baseInterval + Math.random() * TEN_SECONDS_IN_MS)
      }, timeout)
    }

    scheduleAnimation(
      immediate ? 2000 : baseInterval + Math.random() * TEN_SECONDS_IN_MS
    )
  }
}
