import { initializeCarousels } from './carousel'
import { initializeEyes } from './blinking-eyes'

function getElement (name) {
  return document.querySelector(`[data-js="${name}"]`)
}

/**
 * Main Menu Interaction
 */
const mainMenu = getElement('main-menu')

function closeMainMenu () {
  plausible('close_main_menu')
  mainMenu.style.display = 'none'
  mainMenu.classList.remove('main-menu-in')
}

function openMainMenu () {
  mainMenu.style.display = 'block'
  plausible('click_main_menu_trigger')
  window.requestAnimationFrame(() => {
    window.requestAnimationFrame(() => {
      mainMenu.classList.add('main-menu-in')
      mainMenu.focus()
    })
  })
}

mainMenu.addEventListener('keyup', function (event) {
  if (event.code === 'Escape' && mainMenu.style.display === 'block') {
    closeMainMenu()
  }
})
getElement('main-menu-trigger').addEventListener('click', openMainMenu)
getElement('main-menu-close-button').addEventListener('click', closeMainMenu)

/**
 * CTA Button Tracking
 */
function trackTicketCTAClick () {
  plausible('click_ticket_cta')
}

document.querySelectorAll('[data-js="ticket-cta"]').forEach((element) => {
  element.addEventListener('click', trackTicketCTAClick)
})

/**
 * Newsletter Form
 */
const newsletterForm = getElement('newsletter-form')

if (newsletterForm) {
  const emailInput = newsletterForm.querySelector('input[name="email"]')

  let hasSubmittedForm = false
  emailInput.addEventListener('focus', function () {
    if (!hasSubmittedForm) {
      plausible('begin_newsletter_signup')
    }
    hasSubmittedForm = true
  })

  newsletterForm.addEventListener('submit', function (event) {
    event.preventDefault()

    const button = newsletterForm.querySelector('button[type="submit"]')
    const data = new window.FormData()

    button.textContent = '⏳'
    button.disabled = true
    emailInput.disabled = true
    data.append('email', emailInput.value)

    plausible('submit_newsletter_signup')

    function done () {
      hasSubmittedForm = true
      button.textContent = '✔'
    }

    window
      .fetch(newsletterForm.action, {
        method: newsletterForm.method,
        mode: 'cors',
        redirect: 'manual',
        body: data
      })
      .then(done)
      .catch((error) => {
        // swallow errors from the newsletter provider
        console.error(error)
        done()
      })
  })
}

window.addEventListener('load', () => {
  initializeEyes()
  initializeCarousels()

  // Initialize link prefetching
  if ('IntersectionObserver' in window && !window.Cypress) {
    import('quicklink').then((module) => module.listen())
  }
})
