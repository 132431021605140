// Image Carousel

export function initializeCarousels () {
  if (!('scrollBehavior' in document.documentElement.style)) {
    import('smoothscroll-polyfill').then((module) => {
      module.polyfill()
    })
  }

  /**
   * @type {NodeListOf<HTMLDivElement>}
   */
  const carousels = document.querySelectorAll('[data-js="image-carousel"]')

  carousels.forEach((carousel) => {
    carousel.addEventListener('click', (event) => {
      const carouselWidth = carousel.getBoundingClientRect().width

      if (carouselWidth > 0) {
        // Give carousels a full pixel width to avoid subpixel offset problems when scrolling
        carousel.style.width = `${Math.round(carouselWidth)}px`
      }

      if ('left' in event.target.dataset) {
        carousel.scroll({
          left: carousel.scrollLeft - carousel.offsetWidth,
          behavior: 'smooth'
        })
        return
      }

      if ('right' in event.target.dataset) {
        carousel.scroll({
          left: carousel.scrollLeft + carousel.offsetWidth,
          behavior: 'smooth'
        })
      }
    })
  })
}
